<template>
  <div :ref="id" :style="{ width: width, height: height }"></div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'a'
    },
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '500px'
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.chart = this.$echarts.init(this.$refs[this.id])
      this.initChart()
    })
  },
  beforeDestroy() {},
  methods: {
    initChart() {
      this.setOptions(this.chartData)
    },
    setOptions(data) {
      this.chart.setOption({
        series: [
          {
            name: '层级架构图',
            type: 'tree',
            orient: 'TB', //竖向或水平   TB代表竖向  LR代表水平
            top: '10%',
            roam: true,
            initialTreeDepth: 5, //树图初始展开的层级（深度）
            expandAndCollapse: true, //点击节点时收起子节点
            itemStyle: {
              color: 'transparent',
              borderWidth: 0
            },
            edgeShape:'polyline',
            lineStyle: {
              color: 'rgba(19, 23, 61, 0.5)',
              width: 1,
              curveness: 1
            },
            data: [data]
          }
        ]
      })
    }
  }
}
</script>