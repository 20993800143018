<template>
  <div class="container">
    <!-- 数据 -->
    <div class="top">
      <div class="t_left">
        <div class="t_title">${{ total_data.AllAmount }}</div>
        <div class="t_msg">{{ $t('direct').AllAmount }}</div>
        <div class="t_title">${{ total_data.GratitudeAmount }}</div>
        <div class="t_msg">{{ $t('direct').GratitudeAmount }}</div>
      </div>
      <div class="t_mid">
        <div class="t_title">{{ total_data.TeamNum }}</div>
        <div class="t_msg">{{ $t('direct').Totalpeople }}</div>
        <div class="t_title">{{ total_data.InvitationNum }}</div>
        <div class="t_msg">{{ $t('direct').Directpush }}</div>
      </div>
      <div class="t_right">
        <div class="t_title">{{ total_data.TeamOneStar }}</div>
        <div class="t_msg">{{ $t('direct').starAbove }}</div>
        <div class="t_title">{{ total_data.InvitationOneStar }}</div>
        <div class="t_msg">{{ $t('direct').starAbove }}</div>
      </div>
    </div>
    <!-- 页签 -->
    <div class="navTab">
      <div
        class="item"
        @click="activeNav = 'direct'"
        :class="{ activeNav: activeNav == 'direct' }"
      >
        {{ $t('direct').Directpush.toUpperCase() }}
      </div>
      <div
        class="item"
        @click="activeNav = 'statistics'"
        :class="{ activeNav: activeNav == 'statistics' }"
      >
        {{ $t('direct').Statistics.toUpperCase() }}
      </div>
      <div
        class="item"
        @click="activeNav = 'connections'"
        :class="{ activeNav: activeNav == 'connections' }"
      >
        {{ $t('direct').Connections.toUpperCase() }}
      </div>
      <div
        class="item"
        @click="activeNav = 'architecture'"
        :class="{ activeNav: activeNav == 'architecture' }"
      >
        {{ $t('direct').Architecture.toUpperCase() }}
      </div>
    </div>
    <!-- 直推 -->
    <div class="direct" v-if="activeNav == 'direct'" @scroll="handleScroll">
      <div class="di_title">{{ $t('direct').MyDirectRecommendation }}</div>
      <div class="di_item" v-for="(item, index) in direct_data" :key="index">
        <img
          :src="
            item.HeadImg
              ? item.HeadImg
              : require('@/assets/newImg/direct_i_heard.png')
          "
          width="24px"
          height="24px"
        />
        <div class="di_i_name">{{ item.NickName }}</div>
        <div class="di_i_star">
          {{ item.GradeType }} {{ $t('common').Star.toUpperCase() }}
        </div>
        <!-- <img src="@/assets/newImg/direct_i_mobile.png" width="10px" /> -->
        <div class="di_i_num">{{ item.ContactInformation }}</div>
      </div>
      <NoData v-if="direct_data.length === 0" />
    </div>
    <!-- 团队 -->
    <div class="direct" v-if="activeNav == 'statistics'">
      <div class="di_title">{{ $t('direct').TeamStatistics }}</div>
      <div class="di_item">
        <div class="di_i_msg">{{ $t('direct').Ordinarymember }}</div>
        <div class="di_i_people">
          {{ statistics_data.NoStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">1 {{ $t('direct').starMember }}</div>
        <div class="di_i_people">
          {{ statistics_data.OneStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">2 {{ $t('direct').starMember }}</div>
        <div class="di_i_people">
          {{ statistics_data.TwoStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">3 {{ $t('direct').starMember }}</div>
        <div class="di_i_people">
          {{ statistics_data.ThreeStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">4 {{ $t('direct').starMember }}</div>
        <div class="di_i_people">
          {{ statistics_data.FourStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">5 {{ $t('direct').starMember }}</div>
        <div class="di_i_people">
          {{ statistics_data.FiveStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">6 {{ $t('direct').starMember }}</div>
        <div class="di_i_people">
          {{ statistics_data.SixStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">7 {{ $t('direct').starMember }}</div>
        <div class="di_i_people">
          {{ statistics_data.SevenStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">8 {{ $t('direct').starMember }}</div>
        <div class="di_i_people">
          {{ statistics_data.EightStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">9 {{ $t('direct').starMember }}</div>
        <div class="di_i_people">
          {{ statistics_data.NineStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">10 {{ $t('direct').starMember }}</div>
        <div class="di_i_people">
          {{ statistics_data.TenStarNum }} {{ $t('direct').people }}
        </div>
      </div>
    </div>
    <!-- 人脉 -->
    <div class="direct" v-if="activeNav == 'connections'">
      <div class="di_title">{{ $t('direct').HierarchicalConnections }}</div>
      <div class="di_item">
        <div class="di_i_msg">1st {{ $t('direct').degreeConnections }}</div>
        <div class="di_i_people">
          {{ connections_data.OneStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">2st {{ $t('direct').degreeConnections }}</div>
        <div class="di_i_people">
          {{ connections_data.TwoStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">3st {{ $t('direct').degreeConnections }}</div>
        <div class="di_i_people">
          {{ connections_data.ThreeStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">4st {{ $t('direct').degreeConnections }}</div>
        <div class="di_i_people">
          {{ connections_data.FourStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">5st {{ $t('direct').degreeConnections }}</div>
        <div class="di_i_people">
          {{ connections_data.FiveStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">6st {{ $t('direct').degreeConnections }}</div>
        <div class="di_i_people">
          {{ connections_data.SixStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">7st {{ $t('direct').degreeConnections }}</div>
        <div class="di_i_people">
          {{ connections_data.SevenStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">8st {{ $t('direct').degreeConnections }}</div>
        <div class="di_i_people">
          {{ connections_data.EightStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">9st {{ $t('direct').degreeConnections }}</div>
        <div class="di_i_people">
          {{ connections_data.NineStarNum }} {{ $t('direct').people }}
        </div>
      </div>
      <div class="di_item">
        <div class="di_i_msg">10st {{ $t('direct').degreeConnections }}</div>
        <div class="di_i_people">
          {{ connections_data.TenStarNum }} {{ $t('direct').people }}
        </div>
      </div>
    </div>
    <!-- 层级架构图 -->
    <div class="direct" v-if="activeNav == 'architecture'">
      <div class="di_title">{{ $t('direct').ArchitectureDiagram }}</div>
      <EcharTree :chartData="treeData" id="a" />
    </div>
    <TabBar :active="'Direct'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import TabBar from '@/components/tabBarNew'
import EcharTree from '@/components/echartTree'
import NoData from '@/components/noData'
export default {
  components: {
    NoData,
    TabBar,
    EcharTree
  },
  data() {
    return {
      activeNav: 'direct',
      noList: false,
      page: 1,
      size: 10,
      total_data: {},
      direct_data: [],
      statistics_data: [],
      connections_data: [],
      treeData: {}
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection', 'baseMsg'])
  },
  mounted() {
    if (this.$route.query.Type) this.activeNav = this.$route.query.Type
    this.getMyteamStatistics()
    this.getMyteamMemberNum()
    this.getMyInvitationMemberNum()
    this.getMyteam()
    this.getMyPushUserList()
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 获取团队统计
    async getMyteamStatistics() {
      const res = await userApi.getMyteamStatistics()
      this.total_data = res.Data
    },
    //滑动事件
    handleScroll(e) {
      if (!this.noList) {
        this.noList = true
        this.page = this.page + 1
        this.getMyPushUserList()
      }
    },
    // 获取直推列表
    async getMyPushUserList() {
      var form = {
        page: this.page,
        size: this.size
      }
      const res = await userApi.getMyPushUserList(form)
      var list = res.Data
      if (this.page == 1) {
        this.direct_data = list
      } else {
        this.direct_data = this.direct_data.concat(list)
      }
      if (list.length > 0) this.noList = false
      else this.noList = true
    },
    // 获取团队人数统计
    async getMyteamMemberNum() {
      const res = await userApi.getMyteamMemberNum()
      this.statistics_data = res.Data
    },
    // 获取层级人脉统计
    async getMyInvitationMemberNum() {
      const res = await userApi.getMyInvitationMemberNum()
      this.connections_data = res.Data
    },
    // 获取团队架构
    async getMyteam() {
      const res = await userApi.getMyteam()
      this.treeData = res.Data
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(-90deg, #492fb6 0%, #7f49c5 100%);
  border-radius: 0px 0px 10px 10px;
  padding: 20px 0 40px 0;
}
.top {
  margin: 0 10px 10px 10px;
  height: 140px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(43, 49, 84, 0.75);
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .t_left {
    width: 50%;
    height: 140px;
  }
  .t_right {
    width: 50%;
    height: 140px;
  }
  .t_mid {
    margin-top: -2px;
    width: 50%;
    height: 147px;
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(43, 49, 84, 0.75);
    border-radius: 15px;
  }
  .t_title {
    margin-top: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 18px;
    color: #000;
    text-align: center;
  }
  .t_msg {
    margin-top: 15px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 9px;
    color: #000;
    text-align: center;
  }
}
.navTab {
  margin: 0 5px;
  display: flex;
  justify-content: space-between;
  height: 45px;
  background-image: url(../../assets/newImg/regedit_tab.png);
  background-size: 100% 100%;
  padding: 0 10px;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 9px;
    color: #ffffff;
    margin: 7px 0 10px 0;
  }
  .activeNav {
    color: #9a5300;
    background-image: url(../../assets/newImg/active_btn.png);
    background-size: 100% 100%;
  }
}
.direct {
  margin: 5px 10px 30px 10px;
  background: #ffffff;
  border-radius: 15px;
  padding: 15px 10px 1px 10px;
  .di_title {
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 15px;
    color: #4a30b7;
    margin-bottom: 15px;
  }
  .di_item {
    height: 50px;
    background: rgba(255, 139, 2, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 10px;
    .di_i_name {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 15px;
      color: #333333;
      width: 30%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .di_i_star {
      background: linear-gradient(360deg, #ff9002 0%, #fcb81d 100%);
      border-radius: 5px;
      width: 41px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 8px;
      color: #ffffff;
    }
    .di_i_num {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 12px;
      color: #333333;
      width: 35%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .di_i_msg {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 15px;
      color: #333333;
    }
    .di_i_people {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 12px;
      color: #333333;
    }
  }
}
</style>
